<template>
  <div class="warp">
    <div class="title">普通搜索</div>

    <el-form
      ref="form"
      :model="form"
      :label-width="size !== 'dialog' ? '120px' : '80px'"
    >
      <el-row :gutter="20">
        <!-- 短视频 -->
        <el-col :span="6">
          <el-form-item label="编辑者">
            <el-input v-model="form.edit_by"></el-input>
          </el-form-item>
        </el-col>

        <!-- 视频id -->
        <el-col :span="6">
          <el-form-item label="ID">
            <el-input type="number" v-model.number="form.id"></el-input>
          </el-form-item>
        </el-col>

        <!-- acg动漫id -->
        <el-col :span="6" v-if="size === 'dialog'">
          <el-form-item label="acg动漫id">
            <el-input type="number" v-model.number="form.media_id"></el-input>
          </el-form-item>
        </el-col>

        <!-- 视频id -->
        <el-col :span="6" v-if="size === 'dialog'">
          <el-form-item label="文件状态">
            <el-select v-model="form.fs_resource_status" placeholder="请选择">
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in resourceList"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 视频 + 短视频公共 -->
        <el-col :span="6">
          <el-form-item label="来源产品">
            <el-input v-model="form.from_pro_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="标签" v-if="size !== 'dialog'">
            <el-input v-model="form.tags_text"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="size !== 'dialog'">
          <el-form-item label="媒体类型">
            <el-select v-model.number="form.media_type" placeholder="请选择">
              <el-option label="动画" :value="1"></el-option>
              <el-option label="漫画" :value="2"></el-option>
              <el-option label="文本小说" :value="3"></el-option>
              <el-option label="有声小说" :value="4"></el-option>
              <el-option label="短剧" :value="5"></el-option>
              <el-option label="有声漫" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择">
              <el-option label="审核中" :value="0"></el-option>
              <el-option label="审核通过" :value="1"></el-option>
              <el-option label="拒绝" :value="2"></el-option>
              <el-option label="下架" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!-- 短视频查询条件 -->
        <template>
          <el-col :span="6">
            <el-form-item label="上传者">
              <el-input v-model="form.terminal_cdo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="博主id">
              <el-input
                type="number"
                v-model.number="form.vlogger_id"
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col class="cols">
          <el-button type="primary" @click="submit(form)">提交</el-button>
          <el-button @click="resetData">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "tabs.vue",
  data() {
    return {
      activeName: "0",
      form: {
        media_type: 1,
      },
      list: [
        {
          label: "全部",
          value: "0",
        },
        {
          label: "待编辑",
          value: "1",
        },
        {
          label: "全部",
          value: "2",
        },
        {
          label: "准备预热",
          value: "3",
        },
        {
          label: "预热中",
          value: "4",
        },
        {
          label: "预热异常",
          value: "5",
        },
        {
          label: "已就绪",
          value: "6",
        },
        {
          label: "已弃用",
          value: "7",
        },
      ],
      // 文件状态
      resourceList: [
        { key: "waiting", value: "待检查" },
        { key: "Uploading", value: "文件上传中" },
        { key: "UploadError", value: "文件上传失败" },
        { key: "UploadCompleted", value: "文件上传完成" },
        { key: "FileMerging", value: "文件合并中" },
        { key: "FileConverting", value: "文件转换中" },
        { key: "FileMergeError", value: "文件合并失败" },
        { key: "FileConvertError", value: "文件转换失败" },
        { key: "CompletedFail", value: "处理失败" },
        { key: "CompressCompleted", value: "压缩完成" },
        { key: "Completed", value: "处理完成" },
      ],
      // 是否竖版
      verticalList: [
        { key: true, value: "是" },
        { key: false, value: "否" },
      ],
      // 媒体类型
      mediaList: [
        { key: 0, value: "视频" },
        { key: 1, value: "图文" },
      ],
      // 状态
      statuslist: [
        {
          key: 0,
          value: "准备中",
        },
        {
          key: 7,
          value: "弃用",
        },
        {
          key: 10,
          value: "完成&可使用",
        },
      ],
      // 连载名称
      setsIdlist: [{ key: 0, value: "" }],
      // 时长
      lengthlist: [
        { key: null, value: "全部" },
        { key: "max", value: "小于10分钟" },
        { key: "min", value: "10分钟以上" },
      ],
      create_time_form: "",
    };
  },
  props: ["delUrl", "parentsList", "size", "fileType"],
  methods: {
    handleClick() {},
    resetData() {
      this.form = {};
      this.create_time_form = "";

      this.$emit("resetData", {
        page: 1,
        size: 10,
        media_type: 1,
        reset: true,
      });
    },
    submit(data) {
      // 入库时间
      if (this.create_time_form) {
        let creat = new Date(this.create_time_form[0]).getTime() / 1000;
        let end = new Date(this.create_time_form[1]).getTime() / 1000;

        data.create_time = {
          max: end,
          min: creat,
        };
      } else {
        data.create_time = null;
      }
      // 时长
      if (data.length) {
        data.length == "max"
          ? (data.length = { max: 599 })
          : (data.length = { min: 600 });
      }
      data.page = 1;
      data.inFrom = true;
      this.$emit("searchData", data);
    },
  },
};
</script>

<style scoped lang="scss">
.warp ::v-deep .el-date-editor,
.warp ::v-deep .el-select {
  width: 100% !important;
}
.el-form-item {
  margin-bottom: 10px;
}
.title {
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  padding: 0 0 10px 0;
  box-sizing: border-box;
  margin: 0 0 20px 0;
}
.cols {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 20px 10px 0;
  .el-button {
    margin: 0 0 0 10px;
  }
}
</style>

<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--操作栏-->
    <tabs
      v-if="isSearch"
      @searchData="searchData"
      @resetData="searchData"
    ></tabs>
    <refreshbtn :isBetWeen="true">
      <template slot="left_btn" v-if="!userInfo.is_outer">
        <el-button size="small" type="success" @click="showDialog"
          >批量审核</el-button
        >
        <el-button size="small" type="danger" @click="openSetTags"
          >批量设置标签</el-button
        >
        <el-button size="small" type="success" @click="deleteTable(true)"
          >一键删除</el-button
        >
        <!-- <el-button size="small" type="success" @click="deleteData"
          >子集批量审核</el-button
        >
        <el-button size="small" type="success" @click="deleteData"
          >子集批量删除</el-button
        > -->
      </template>
      <template slot="search-btns">
        <el-button
          title="普通搜索"
          size="small"
          icon="el-icon-search"
          @click="isSearch = !isSearch"
        ></el-button>
      </template>
    </refreshbtn>
    <!--数据表格-->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      ref="multipleTable"
      v-loading="loading"
      element-loading-text="拼命加载中"
      @selection-change="handleSelectionChange"
      element-loading-spinner="el-icon-loading"
      height="580"
      :key="updataSta"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        align="center"
        min-width="240"
        :show-overflow-tooltip="false"
      ></el-table-column>
      <el-table-column
        prop="total_episode"
        label="总集数"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag style="margin: 5px 0" type="success">
            <span v-if="scope.row.status == 1">审核通过</span>
            <span v-else-if="scope.row.status == 2">拒绝</span>
            <span v-else-if="scope.row.status == 3">下架</span>
            <span v-else>审核中</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="media_type"
        label="媒体类型"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.media_type == 1">动画</span>
          <span v-else-if="scope.row.media_type == 2">漫画</span>
          <span v-else-if="scope.row.media_type == 3">小说</span>
          <span v-else-if="scope.row.media_type == 5">短剧</span>
          <span v-else-if="scope.row.media_type == 6">有声漫</span></template
        ></el-table-column
      >
      <el-table-column
        prop="update_status"
        label="更新状态"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.update_status == 1">连载中</span>
          <span v-else>已完结</span>
        </template></el-table-column
      >
      <el-table-column
        prop="vertical_cover"
        label="竖版封面"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            :key="scope.row.vertical_cover"
            :preview-src-list="[handleImageUrl(scope.row.vertical_cover)]"
            :src="handleImageUrl(scope.row.vertical_cover)"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="horizontal_cover"
        label="横板封面"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            :key="scope.row.vertical_cover"
            :preview-src-list="[handleImageUrl(scope.row.vertical_cover)]"
            :src="handleImageUrl(scope.row.horizontal_cover)"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="sale_coin"
        label="整部售价"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="标签" align="center" width="180">
        <template slot-scope="scope">
          <el-tag style="margin: 5px 0" type="success">
            {{ scope.row.tags_text }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="reason"
        label="拒绝理由"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="anchor"
        label="作者"
        align="center"
        width="120"
        :show-overflow-tooltip="false"
      ></el-table-column>
      <el-table-column
        prop="edit_by"
        label="编辑者"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="terminal_cdo"
        label="上传者"
        align="center"
        width="140"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="from_pro_id"
        label="来源平台"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="create_time"
        label="创建时间"
        align="center"
        width="180"
      >
        <template slot-scope="scope">
          {{
            moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")
          }}
        </template></el-table-column
      >
      <el-table-column
        prop="data_version"
        label="数据版本"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="is_delete"
        label="是否删除"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_delete == 1">已删除</span>
          <span v-else>未删除</span>
        </template></el-table-column
      >

      <el-table-column
        prop="create_time"
        label="操作"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editForm(scope.row)">
            查看子集
          </el-button>
          <el-button type="text" size="small" @click="editData(scope.row, 1)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <media-popup ref="mediaPopup"></media-popup>
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="searchFrom.page"
          :page-size="searchFrom.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="查看ACG子集"
      :append-to-body="true"
      :visible.sync="acgDialog"
      width="1200"
    >
      <tabs
        @searchData="editForm"
        :fileType="fileTypeList"
        :size="'dialog'"
        @resetData="editForm"
      ></tabs>
      <el-button size="small" type="success" @click="showDialog1"
        >子集批量审核</el-button
      >
      <el-button size="small" type="success" @click="deleteTable(false)"
        >子集批量删除</el-button
      >
      <el-table
        ref="multipleTable"
        :data="tableSm"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange1"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="episode_number"
          label="第几集"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="章节名"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="media_type"
          label="播放"
          width="200"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.media_type == 1 ||
                scope.row.media_type == 5 ||
                scope.row.media_type == 6
              "
              @click="lookVideoDialogFun(scope.row)"
              size="mini"
              >播放动画</el-button
            >
            <el-image
              v-if="scope.row.urls && scope.row.media_type == 2"
              style="width: 100px; height: 100px"
              :src="handleImageUrl(scope.row.urls.split(',')[0])"
              :preview-src-list="
                scope.row.urls.split(',').map((v) => handleImageUrl(v))
              "
            >
            </el-image>
            <div v-if="scope.row.media_type == 3">
              <el-button @click="setText(scope.row.text)" size="mini"
                >查看内容</el-button
              >
              <br />
              <audio controls>
                <source
                  :src="handleImageUrl(scope.row.audio_url)"
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="media_type"
          label="媒体类型"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="fs_resource_status"
          label="文件状态"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
              fileTypeList.find((v) => v.value == scope.row.fs_resource_status)
                .label
            }}
            <!-- {{scope.row.fs_resource_status}} -->
          </template></el-table-column
        >
        <!-- <el-table-column prop="fs_resource_status" label="文件状态" align="center" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                   {{fs_resource_status}}
                  </template>
                </el-table-column>  -->
        <el-table-column
          prop="status"
          label="状态"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag style="margin: 5px 0" type="success">
              <span v-if="scope.row.status == 1">审核通过</span>
              <span v-else-if="scope.row.status == 2">拒绝</span>
              <span v-else-if="scope.row.status == 3">下架</span>
              <span v-else>审核中</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="sale_coin"
          label="价格"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="cover"
          label="封面"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-image
              :key="scope.row.cover"
              :preview-src-list="[handleImageUrl(scope.row.cover)]"
              :src="handleImageUrl(scope.row.cover)"
            ></el-image> </template
        ></el-table-column>
        <el-table-column
          prop="play_time"
          label="影片长度"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="urls"
          label="地址集"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- {{ scope.row.urls.split(",")[0] }} -->
            <el-image
              v-if="scope.row.urls"
              style="width: 100px; height: 100px"
              :src="handleImageUrl(scope.row.urls.split(',')[0])"
              :preview-src-list="
                scope.row.urls.split(',').map((v) => handleImageUrl(v))
              "
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="media_size"
          label="资源大小"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="width"
          label="宽"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="height"
          label="高"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="ratio"
          label="宽高比"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="reason"
          label="拒绝理由"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="edit_by"
          label="编辑者"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <!-- 
                <el-table-column prop="audio_url" label="有声小说地址" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="review_by" label="编辑相关" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="text" label="内容" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="video_id" label="媒体资源ID" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="is_delete" label="是否删除" align="center" :show-overflow-tooltip="true"> 
                 <template slot-scope="scope">
                    <span v-if="scope.row.is_delete == 1">已删除</span>
                    <span v-else>未删除</span>
                </template>
              </el-table-column> 
       -->

        <el-table-column prop="create_time" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="lookVideo(scope.row)"
              v-if="scope.row.fs_resource_status !== 'Completed'"
            >
              刷新状态
            </el-button>
            <el-button type="text" size="small" @click="editData(scope.row, 2)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <div class="bottom">
        <div class="page">
          <el-pagination
            :page-sizes="[10, 25, 50, 100]"
            :current-page="dialogPage.page"
            :page-size="dialogPage.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dialogPage.total"
            background
            v-if="dialogPage.total > 10"
            @size-change="changeSize1"
            @current-change="changeCurrent1"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="acgDialog = false">取 消</el-button>
        <el-button type="primary" @acgDialog="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="修改ACG状态"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in statusList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="拒绝原因"
          v-show="form.status === 2"
          style="margin-bottom: 20px"
        >
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPushData">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="更新数据"
      :append-to-body="true"
      :visible.sync="updateDialog"
      width="700px"
    >
      <newAcg ref="newAcg" v-if="bool === 1" @close="closeDialog"></newAcg>
      <newAcgDetail
        ref="newAcgDetail"
        @lookVideos="lookVideoDialogFun"
        @close="closeDialog"
        v-else
      ></newAcgDetail>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <lookVideoDialog ref="lookDialog"></lookVideoDialog>
    <el-dialog title="查看小说" :visible.sync="dialogVisibles" width="300">
      <p
        style="max-height: 500px; overflow: auto; white-space: pre-line"
        v-html="text"
      ></p>
    </el-dialog>
    <!--批量标签话题编辑弹窗-->
    <batch-tags-popup
      ref="batchTagsPopup"
      type="movie"
      @handlerClose="handlerClose"
    ></batch-tags-popup>
  </div>
</template>

<script>
import MediaPopup from "@/components/media-popup";
import { changeTime } from "@/utils/global";
import local from "@/utils/localStroage.js";
import tabs from "./components/tabs";
import newAcg from "./components/newAcg";
import newAcgDetail from "./components/newAcgDetail";
import lookVideoDialog from "../medium/components/lookVideoDialog";
import BatchTagsPopup from "./components/batchTagsPopup";
import moment from "moment";

import API from "@/api";
export default {
  data() {
    return {
      // 状态
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "通过",
          value: "10",
        },
        {
          label: "未通过",
          value: "7",
        },
        {
          label: "处理中",
          value: "0,6",
        },
      ],
      statusList: [
        {
          label: "审核通过",
          value: 1,
        },
        {
          label: "拒绝",
          value: 2,
        },
        {
          label: "下架",
          value: 3,
        },
      ],
      moment: moment,
      fileTypeList: [
        {
          value: "Waiting",
          label: "待检查",
        },
        {
          value: "Uploading",
          label: "文件上传中",
        },
        {
          value: "UploadError",
          label: "文件上传失败",
        },
        {
          value: "UploadCompleted",
          label: "文件上传完成",
        },
        {
          value: "FileMerging",
          label: "文件合并中",
        },
        {
          value: "FileConverting",
          label: "文件转换中",
        },
        {
          value: "FileMergeError",
          label: "文件合并失败",
        },
        {
          value: "FileConvertError",
          label: "文件转换失败",
        },
        {
          value: "CompletedFail",
          label: "处理失败",
        },
        {
          value: "CompressCompleted",
          label: "压缩完成",
        },
        {
          value: "Completed",
          label: "处理完成",
        },
        {
          value: "CheckFail",
          label: "检查状态失败",
        },
      ],
      loading: false,
      // 列表数据
      tableData: [],
      updateDialog: false,
      total: "",
      time: "",
      form: {},
      text: "",
      // 请求列表查询
      params: {
        pageNumber: 1,
        pageSize: 10,
        start: "",
        end: "",
        start1: 0,
        end1: 0,
        title: "",
        status: "",
        id: "",
      },
      editId: "",
      tableSm: [],
      userInfo: {},
      searchFrom: { size: 10, page: 1 },
      dialogVisibles: false,
      isSearch: true,
      bool: 1,
      dialogPage: {
        size: 10,
        page: 1,
        total: 1,
      },
      multipleSelection: [],
      videoIds: [],
      multipleSelection1: [],
      dialogVisible: false,
      isDisabled: false,
      updataSta: false,
      tableSmObj: {},
      selectData: {},
      acgDialog: false,
    };
  },
  components: {
    MediaPopup,
    tabs,
    newAcg,
    newAcgDetail,
    lookVideoDialog,
    BatchTagsPopup,
  },
  created() {
    this.searchData();
    this.userInfo = local.get("userInfo");
  },

  methods: {
    // 打开批量设置标签
    async openSetTags() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: "warning",
          message: "请选择要批量设置标签的数据",
        });
        return false;
      }
      let ids = [];
      this.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      this.$refs.batchTagsPopup.open(ids);
    },
    handleImageUrl(val) {
      if (val.indexOf("http") === 0) {
        val = "/" + val.split("/").slice(3).join("/");
      } else if (val.indexOf("/") !== 0) {
        val = "/" + val;
      }
      // 根据去掉域名后的数据进行匹配
      let strValue = "";
      let data = JSON.parse(JSON.parse(localStorage.getItem("imageUrl")));
      for (const key in data) {
        if (key.indexOf("/") !== 0 && key !== "*") {
          //如果匹配键名前面不带'/'
          if (val.indexOf("/" + key + "/") === 0) {
            //
            strValue = data[key];
          }
        } else if (key.indexOf("/") === 0 && key !== "*") {
          //带'/'
          if (val.indexOf(key + "/") === 0) {
            //有匹配的
            strValue = data[key];
          }
        }
      }
      if (strValue) {
        return strValue + val;
      } else {
        return data["*"] + val;
      }
    },
    handlerClose() {
      this.searchData();
    },
    closeDialog() {
      this.updateDialog = false;
    },
    submit() {
      if (this.bool === 1) {
        this.$refs.newAcg.submitForm();
      } else {
        this.$refs.newAcgDetail.submitForm();
      }
    },
    editData(data, bool) {
      this.bool = bool;
      this.updateDialog = true;
      this.$nextTick(() => {
        if (bool === 1) {
          this.$refs.newAcg.setFormData(data);
        } else {
          this.$refs.newAcgDetail.setFormData(data);
        }
      });
    },
    submitPushData() {
      if (this.form.status) {
        if (this.form.status === 2 && !this.form.reason) {
          this.$message.error("请填写拒绝理由");
          return false;
        }
        console.log(this.bool);
        if (this.bool === 1) {
          let data = {
            ids: this.multipleSelection.map((v) => v.id),
          };
          API.acgUpdateList({ ...data, ...this.form }).then((res) => {
            this.$message.success("批量更新成功");
            this.searchData();
            this.dialogVisible = false;
            console.log(res);
          });
        } else {
          let data = {
            ids: this.multipleSelection1.map((v) => v.id),
          };
          API.acgDetailUpdateList({ ...data, ...this.form }).then((res) => {
            this.$message.success("批量更新成功");
            this.editForm({ page: 1, size: 10, media_type: 1 });
            this.dialogVisible = false;
            console.log(res);
          });
        }
      } else {
        this.$message.error("请填写完整");
      }
    },
    deleteTable(bool) {
      if (bool) {
        if (!this.multipleSelection.length) {
          this.$message.error("请先选择数据");
        } else {
          API.acgDeleteList({
            ids: this.multipleSelection.map((v) => v.id),
          }).then((res) => {
            this.$message.success("删除成功");
            this.searchData();
            console.log(res);
          });
        }
      } else {
        if (!this.multipleSelection1.length) {
          this.$message.error("请先选择数据");
        } else {
          API.acgDetailDeleteList({
            ids: this.multipleSelection1.map((v) => v.id),
          }).then((res) => {
            this.$message.success("删除成功");
            this.editForm({ page: 1, size: 10, media_type: 1 });
            console.log(res);
          });
        }
      }
    },
    editForm(obj) {
      console.log(obj, "RISE");
      if (obj.id && !obj.inFrom) {
        this.dialogPage.media_id = obj.id;
        this.editId = obj.id;
      }
      if (obj.inFrom) {
        this.dialogPage = { ...this.dialogPage, ...obj };
      }
      if (obj.reset) {
        // this.dialogPage = {
        //   media_id: this.editId,
        //   size: 10,
        //   page: 1,
        // };
        this.dialogPage.page = 1;
        this.dialogPage.media_id = this.editId;
      }
      this.dialogPage.size = obj.size || 10;
      this.dialogPage.page = obj.page || 1;
      delete this.dialogPage.media_type;
      API.getAcgDetailData(this.dialogPage).then((res) => {
        console.log(res.code === 0);
        if (res.code === 0) {
          this.tableSm = res.data.data || [];
          this.dialogPage.total = res.data.pagination.count;
        }
      });
      this.acgDialog = true;
    },
    lookVideo(data) {
      API.reloadDetailData({ id: data.id }).then((res) => {
        this.editForm({ page: 1, size: 10, media_type: 1 });
        this.$message.success("刷新成功");
        console.log(res);
      });
    },
    lookVideoDialogFun(data) {
      console.log(data);
      this.$refs.lookDialog.openlookDialog = true;
      this.$refs.lookDialog.loadVideo(data.m3u8_url);
    },
    // 获取列表
    searchData(type) {
      var params = {
        page: this.params.pageNumber,
        size: this.params.pageSize,
      };

      if (Object.prototype.toString.call(type) === "[object Object]") {
        console.log(type, "12312");
        params = { ...params, ...type };

        this.searchFrom = params;
        if (type.reset) {
          this.searchFrom.page = 1;
        }
      }
      if (type === "status") {
        this.searchFrom.page = 1;
      }
      if (this.searchFrom.status !== "") {
        params["filter[status]"] = this.params.status;
      }
      if (this.searchFrom.title !== "") {
        params["filter[title]"] = this.params.title;
      }
      if (this.searchFrom.start1 > 0) {
        params["filter[create_time]"] =
          String(this.params.start1) + "," + String(this.params.end1);
      }
      this.loading = true;
      if (!this.searchFrom.media_type) {
        this.searchFrom.media_type = 1;
      }
      API.getAcgListData(this.searchFrom)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.total = res.data.pagination.count;
            if (this.isDisabled) {
              this.restoreCheckedState();
            }
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.tableData = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    // 分页条数改变回调
    changeSize1(size) {
      this.dialogPage.size = size;
      this.editForm({ size });
    },
    // 分页条数改变回调
    changeSize(size) {
      this.searchFrom.size = size;
      this.searchData();
    },
    // 分页页码改变回调
    changeCurrent1(number) {
      this.dialogPage.page = number;
      this.editForm({ page: number, size: this.dialogPage.size });
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.searchFrom.page = number;
      this.searchData();
    },
    // 时间触发回调
    changeTime(time) {
      if (time) {
        this.params.start = changeTime(time[0]) || "";
        this.params.end = changeTime(time[1]) || "";
        this.params.start1 = time[0].getTime() / 1000;
        this.params.end1 = time[1].getTime() / 1000;
      } else {
        this.params.start = "";
        this.params.end = "";
        this.params.start1 = 0;
        this.params.end1 = 0;
      }
      this.searchData("status");
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setText(text) {
      this.text = text;
      console.log(text);
      this.dialogVisibles = true;
    },
    showDialog1() {
      if (!this.multipleSelection1.length) {
        this.$message.error("请先选择数据");
      } else {
        this.dialogVisible = true;
        this.bool = 2;
      }
    },
    showDialog() {
      if (!this.multipleSelection.length) {
        this.$message.error("请先选择数据");
      } else {
        this.dialogVisible = true;
        this.bool = 1;
      }
    },
    // 编辑回调监听
    handlerVideoList() {
      this.searchData();
    },
    handlerPreviewList() {
      this.searchData();
    },
    // 查看媒体
    checkMedia(url, type) {
      this.$refs.mediaPopup.open(url, type);
    },
    // 编辑视频
    edit(row) {
      this.$refs.editVideo.open(row);
    },
    // 翻页时的勾选回显
    restoreCheckedState() {
      /**
       * ①获取到当前数据表格中已经被选中的数据
       */
      const indexs = this.tableData
        .map((item) => {
          let flag = false;
          this.videoIds.forEach((value) => {
            value.id === item.id ? (flag = true) : "";
          });
          if (flag) {
            return item;
          }
        })
        .filter((item) => item);
      /**
       * ②回显当前数据表格中已经被勾选过的数据
       */
      this.$nextTick(() => {
        indexs.forEach((rows) => {
          this.$refs.multipleTable.toggleRowSelection(rows);
        });
      });
    },
    giveUp() {
      this.batchContent = "";
      this.isDisabled = false;
      this.videoIds = [];
      this.$refs.multipleTable.clearSelection();
    },
    // 关闭窗口
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.picturePreview {
  max-width: 300px;
  display: flex;
}
.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
}
.bottom {
  margin-top: 20px;
  .btn {
    display: inline-block;
    margin-left: 20px;
  }
  .page {
    display: inline-block;
    float: right;
  }
}
.el-pagination {
  margin-top: 0 !important;
}
.dialog-footer {
  left: 0;
}
.tagBox {
  display: inline-block;
  margin-right: 5px;
}
</style>

<template>
    <el-form   :model="form" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="有声小说地址"  v-if="form.audio_url">
          
                <audio controls>
                <source :src="setMp3(form.audio_url)" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <!-- <el-upload
                class="upload-demo"
                action="#"
                accept=".mp3"
                :http-request="updateFile">
                <el-button size="small" type="primary">点击上传</el-button>
                </el-upload> -->
         </el-form-item>
         <el-form-item label="视频资源地址" v-if="form.media_type == 1">
            <!-- <el-upload
                class="upload-demo"
                action="#"
                :http-request="updateFileVideo">
                <el-button size="small" type="primary">点击上传</el-button>
                </el-upload> -->
            <el-button
            type="text"
            icon="el-icon-video-play"
            v-if="form.m3u8_url"
            @click="$emit('lookVideos', form)"
          ></el-button>
        </el-form-item>
            <el-form-item label="封面" >
                <el-upload
                class="upload-demo"
                action="#"
                :http-request="updateFileFm">
                <el-button size="small" type="primary">点击上传</el-button>
                </el-upload> 
                <el-image 
                  style="height: 100px"
                  :src="setMp3(form.cover)" 
                  :preview-src-list="[setMp3(form.cover)]">
                </el-image>
             </el-form-item>
            <el-form-item label="第几集"  prop="episode_number">
                <el-input v-model.number="form.episode_number"></el-input>
            </el-form-item>
        
  
        <el-form-item label="资源大小">
            <el-input v-model="form.media_size"></el-input>
        </el-form-item>
        <el-form-item label="宽高比">
            <el-input v-model="form.ratio"></el-input>
        </el-form-item>
        <el-form-item label="售价" prop="sale_coin">
            <el-input v-model.number="form.sale_coin"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="text" v-if="form.media_type == 3">
            <el-input v-model="form.text"></el-input>
        </el-form-item>
        <el-form-item label="章节名" prop="title">
            <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="地址集">
            <uploadImage  
            :imageUrl="form.urls"
            @uploadFileUrl="uploadImg"
            @clearImage="clearImage"
            @validate="validateImage"
            :isUpload="true"
            :objKey="'urls'"
            :multiple="true"></uploadImage>
         
        </el-form-item>
        <el-form-item label="宽">
            <el-input v-model="form.width"></el-input>
        </el-form-item>
        <el-form-item label="高">
            <el-input v-model="form.height"></el-input>
        </el-form-item>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </el-form>
  </template>
  
  <script>
  import API from "@/api";
  import uploadImage from "@/views/medium/components/uploadImage";

  export default {
    components:{uploadImage},
    data(){ 
        return{
            form:{},
            fileList:[],
            dialogVisible:false,
            dialogImageUrl:"",
            rules: {
                cover: [
                    { required: true, message: '请输入封面', trigger: 'change' },
                ],
                episode_number: [
                    { required: true, message: '请输入第几集', trigger: 'change' }
                ],
                sale_coin: [
                    { required: true, message: '请输入这一集的售价', trigger: 'change' }
                ],
                text: [
                    { required: true, message: '请输入内容', trigger: 'change' }
                ],
                title: [
                    { required: true, message: '请输入章节名', trigger: 'change' }
                ]
            }
        }
    },
    props:[ ],
    created(){
        console.log(JSON.parse(JSON.parse(localStorage.getItem('imageUrl'))))
    },
    methods:{
        setMp3(val){
            // 判断是否拥有域名 如果有 去掉域名 字符串第一位是'/'
            if(val.indexOf('http') === 0){
                val = '/' + val.split('/').slice(3).join('/')
            }else if(val.indexOf('/')!==0){
                val = '/' + val
            }
            // 根据去掉域名后的数据进行匹配
            let strValue = ""
            let data = JSON.parse(JSON.parse(localStorage.getItem('imageUrl')))
            for (const key in data) {
                if(key.indexOf('/')!==0 && key !=='*'){//如果匹配键名前面不带'/'
                if(val.indexOf('/'+key+'/')===0){//
                    strValue = data[key]
                }
                }else if(key.indexOf('/')===0 && key !=='*'){//带'/'
                if(val.indexOf(key+'/')===0){//有匹配的
                    strValue = data[key]
                }
                }
            }
            if(strValue){
                return strValue+val
            }else{
                return data["*"]+val
            }
        },
        setFormData(data){
            this.form = data
            this.fileList = data.urls.split(",").map((v,i)=>{return {name:i,url:v}})
            console.log(this.fileList)
        },
        uploadImg(key, value,multiple) {
            console.log(key, value,multiple)
            if(multiple){
                let str = this.form[key]+','+value;
                this.$set(this.form,key,str)
            }else{
                this.$set(this.form,key,value)
            }
        },
        clearImage(key,data,multiple){
            if(multiple){
                this.form[key]=data
            }else{
                this.form[key]=''
            }
        },
        validateImage(value,imagesUrl){
            this.$set(this.form,value,imagesUrl)
        },
        
        async updateFileFm(file){
            const form = new FormData();
            form.append('upload', file.file);
            const { data } = await API.fileUpload(form);
            this.$set(this.form,'cover',data.coverImg)
        },
        async updateFile(file){
            const form = new FormData();
            form.append('upload', file.file);
            const { data } = await API.fileUpload(form);
            this.$set(this.form,'audio_url',data.coverImg)
        },
        async updateFileVideo(file){
            const form = new FormData();
            form.append('upload', file.file);
            const { data } = await API.fileUpload(form);
            this.$set(this.form,'m3u8_url',data.coverImg)
        },
        submitForm(){
            this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
                this.form.fs_info = {}
                if(this.form.urls && this.form.urls.length){
                    this.form.urls = this.form.urls.split(',')
                }
                API.acgDetailUpdate(this.form).then(res=>{
                    if(res.code === 0){
                        this.$message.success("更新成功");
                        this.$emit('close')
                    }
                })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .warp ::v-deep .el-date-editor,.warp ::v-deep .el-select{
    width: 100% !important;
  }
  .el-form-item{
    margin-bottom: 10px;
  }
  .title{
    width: 100%;
    border-bottom: 1px solid #EBEEF5;
    padding: 0 0 10px 0;
    box-sizing: border-box;
    margin: 0 0 20px 0;
  }
  .cols{
    display: flex;
    flex-direction: row-reverse;
    margin: 0 20px 10px 0;
    .el-button{
      margin: 0 0 0 10px;
    }
  }
  </style>
  
<template>
  <el-form :rules="rules" ref="ruleForm" :model="form" label-width="120px">
    <el-form-item label="作者" prop="anchor">
      <el-input v-model="form.anchor"></el-input>
    </el-form-item>
    <el-form-item label="横版封面" :prop="form.id ? '' : 'horizontal_cover'">
      <el-upload class="upload-demo" action="#" :http-request="updateFile">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-image
        style="height: 100px"
        :src="setImage(form.horizontal_cover)"
        :preview-src-list="[setImage(form.horizontal_cover)]"
      >
      </el-image>
    </el-form-item>
    <el-form-item label="整本售价">
      <el-input v-model.number="form.sale_coin"></el-input>
    </el-form-item>
    <el-form-item label="简介" :prop="form.id ? '' : 'summary'">
      <el-input v-model="form.summary"></el-input>
    </el-form-item>
    <!-- <el-form-item label="标签" prop="tags">
            <el-input v-model="form.tags"></el-input>
        </el-form-item> -->
    <el-form-item label="标签">
      <div v-for="(item, index) in tagList" :key="index" class="tag-boxs">
        <template v-if="item.tags && item.tags.length">
          <h2>
            <i
              :class="
                !item.isFold ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
              "
              @click="item.isFold = !item.isFold"
            ></i>
            {{ item.cat_name }}
          </h2>
          <el-collapse-transition>
            <template v-if="item.enable && !item.isFold">
              <!-- 多选 -->
              <el-checkbox-group
                v-if="item.is_multiple"
                v-model="newSelectedTags"
              >
                <el-checkbox
                  v-for="itemtag in item.tags"
                  :key="itemtag.id"
                  :label="itemtag.id"
                  >{{ itemtag.tag_name }}</el-checkbox
                >
              </el-checkbox-group>
              <!-- 单选 -->
              <el-radio-group
                v-if="!item.is_multiple && row[index]"
                v-model="row[index].check"
              >
                <el-radio
                  v-for="(itemtag, indextag) in item.tags"
                  :key="indextag"
                  :label="itemtag.id"
                  >{{ itemtag.tag_name }}</el-radio
                >
              </el-radio-group>
            </template>
          </el-collapse-transition>
        </template>
      </div>
    </el-form-item>
    <el-form-item label="标题" prop="title">
      <el-input v-model="form.title"></el-input>
    </el-form-item>
    <el-form-item label="总集数" prop="total_episode">
      <el-input v-model.number="form.total_episode" type="number"></el-input>
    </el-form-item>
    <el-form-item label="更新状态" prop="update_status">
      <el-switch
        v-model="form.update_status"
        :active-value="1"
        :inactive-value="2"
        active-text="连载中"
        inactive-text="已完结"
      >
      </el-switch>
    </el-form-item>
    <el-form-item label="竖版封面" :prop="form.id ? '' : 'vertical_cover'">
      <el-upload class="upload-demo" action="#" :http-request="updateFile1">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-image
        style="height: 100px"
        :src="setImage(form.vertical_cover)"
        :preview-src-list="[setImage(form.vertical_cover)]"
      >
      </el-image>
    </el-form-item>
    <el-form-item label="博主id">
      <el-input v-model="form.vlogger_id"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import API from "@/api";

export default {
  data() {
    return {
      form: {},
      oldForm: {},
      row: [],
      tagList: [],
      rules: {
        summary: [{ required: true, message: "请输入简介", trigger: "change" }],
        anchor: [{ required: true, message: "请输入作者", trigger: "change" }],
        horizontal_cover: [
          { required: true, message: "请输入横版封面", trigger: "change" },
        ],
        tags: [{ required: true, message: "请输入标签", trigger: "change" }],
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        update_status: [
          { required: true, message: "请输入更新状态", trigger: "change" },
        ],
        vertical_cover: [
          { required: true, message: "请输入竖版封面", trigger: "change" },
        ],
      },
      newSelectedTags: [],
    };
  },
  props: [],
  created() {
    this.row = [];
    API.getTagTree({ is_up: false }).then((res) => {
      res.data.forEach((v) => {
        let list = [];
        // if(v.tags.length){
        v.tags.forEach((x) => {
          list.push(x.id);
        });
        if (v.is_multiple) {
          this.row.push({
            check: [],
            type: "check",
            list: list,
            input: "",
            father: v.id,
          });
        } else if (!v.is_multiple) {
          this.row.push({ check: "", type: "radio", list: list });
        }
        // }
      });
      this.tagList = res.data.filter((v) => v.cat_name == "ACG");
    });
  },
  methods: {
    setImage(val) {
      // 判断是否拥有域名 如果有 去掉域名 字符串第一位是'/'
      if (val.indexOf("http") === 0) {
        val = "/" + val.split("/").slice(3).join("/");
      } else if (val.indexOf("/") !== 0) {
        val = "/" + val;
      }
      // 根据去掉域名后的数据进行匹配
      let strValue = "";
      let data = JSON.parse(JSON.parse(localStorage.getItem("imageUrl")));
      for (const key in data) {
        if (key.indexOf("/") !== 0 && key !== "*") {
          //如果匹配键名前面不带'/'
          if (val.indexOf("/" + key + "/") === 0) {
            //
            strValue = data[key];
          }
        } else if (key.indexOf("/") === 0 && key !== "*") {
          //带'/'
          if (val.indexOf(key + "/") === 0) {
            //有匹配的
            strValue = data[key];
          }
        }
      }
      if (strValue) {
        return strValue + val;
      } else {
        return data["*"] + val;
      }
    },
    setFormData(data) {
      this.form = data;
      let value = this.form.tags.split(",");
      this.newSelectedTags = this.form.tag_list.map((item) => {
        return item.id;
      });
      this.row.forEach((v) => {
        if (v.list.length) {
          v.list.forEach((x) => {
            if (value.includes(x.toString())) {
              v.type === "check" ? v.check.push(x) : (v.check = x);
            }
          });
        }
      });
    },
    async updateFile1(file) {
      const form = new FormData();
      form.append("upload", file.file);
      const { data } = await API.fileUpload(form);
      this.$set(this.form, "vertical_cover", data.coverImg);
    },
    async updateFile(file) {
      const form = new FormData();
      form.append("upload", file.file);
      const { data } = await API.fileUpload(form);
      this.$set(this.form, "horizontal_cover", data.coverImg);
    },
    submitForm() {
      let tags = [];
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.row.forEach((v) => {
            if (Array.isArray(v.check)) {
              v.check.forEach((n) => tags.push(n));
            } else if (v.check) {
              tags.push(v.check);
            }
          });
          if (this.newSelectedTags) {
            this.form.tags = this.newSelectedTags;
          } else {
            this.form.tags = tags;
          }
          if (!this.form.tags.length) {
            this.$message.error("请选择标签");
            return false;
          }

          API.acgDataUpdate(this.form).then((res) => {
            if (res.code === 0) {
              this.$message.success("更新成功");
              this.$emit("close");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.warp ::v-deep .el-date-editor,
.warp ::v-deep .el-select {
  width: 100% !important;
}
.el-form-item {
  margin-bottom: 10px;
}
.title {
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  padding: 0 0 10px 0;
  box-sizing: border-box;
  margin: 0 0 20px 0;
}
.cols {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 20px 10px 0;
  .el-button {
    margin: 0 0 0 10px;
  }
}
.tag-boxs {
  font-size: 12px;
}
</style>
